import { useEffect, useState } from "react";
import { RiH3, RiImageAddFill } from "react-icons/ri";
import { ScrubbableVideo } from "../ScrubbableVideo";
import { CroppedVideo } from "../CropWindow";
import { Cropper } from "../Cropper";

export const Fallback = (props: {
    videoSrc: string;
    videoElementId: string;
    parentVideoElementId?: string;
    asPopup: boolean;
    open?: boolean;
    cropper?: CroppedVideo;
    fallbackImage?: number;
    onSetFallbackImage?: (time: number) => void;
    showButton?: boolean;
    cancelButton?: boolean;
    showSlider?: boolean;
    onClose?: any;
    showTitle?: boolean;
    clean?: boolean;
}) => {
    const [frame, setFrame] = useState<number>(props.fallbackImage || 0);
    const [open, setOpen] = useState<boolean>(props.open || false);

    const handleSet = () => {
        const parentVideoId = props.parentVideoElementId || props.videoElementId;
        const video = document.getElementById(parentVideoId) as HTMLVideoElement;
        const fallbackFrameOffset = video.currentTime >= video.duration ? video.duration - 0.05 : video.currentTime;
        console.log("Time", fallbackFrameOffset, video.currentTime);
        setFrame(fallbackFrameOffset);
        // console.log(video);
        props.onSetFallbackImage && props.onSetFallbackImage(fallbackFrameOffset);
        if (props.asPopup) {
            props.onClose();
            setOpen(!open);
        }
    };
    const handleShow = () => {
        const parentVideoId = props.parentVideoElementId || props.videoElementId;
        const video = document.getElementById(parentVideoId) as HTMLVideoElement;
        video.currentTime = frame;
    };

    useEffect(() => {
        const fb = document.getElementById(props.videoElementId) as HTMLVideoElement;
        fb.currentTime = frame;
    }, [frame, props.videoElementId]);

    useEffect(() => {
        if (props.asPopup && !open) {
            props.onClose && props.onClose();
        }
    }, [open, props]);
    return (
        <div className={`fallback-box ${props.asPopup ? "popup" : ""} ${open ? "open" : ""}`}>
            {props.showTitle && <h3>Set fallback image</h3>}
            {!props.clean && (
                <p
                    className="box-title"
                    onClick={() => {
                        setOpen(!open);
                    }}>
                    {props.asPopup ? "" : "Fallback image"}
                </p>
            )}
            <div className="row centered">
                <ScrubbableVideo
                    video={{
                        src: props.videoSrc,
                        id: props.videoElementId,
                        autoPlay: false,
                        loop: false,
                        onLoadedMetadata: () => {},
                        muted: true,
                        className: props.showTitle ? "has-title" : "",
                    }}
                    slider={{
                        style: undefined,
                        onFocus: undefined,
                        onChange: undefined,
                        hidePlay: true,
                        center: true,
                        hide: props.showSlider ? false : true,
                    }}
                    onSetFallbackFrame={undefined}
                    cropper={
                        props.cropper !== undefined ? (
                            <Cropper
                                handleResizeEnd={undefined}
                                handleDragEnd={undefined}
                                handleDrag={undefined}
                                rndConf={undefined}
                                aspectRatio={props.cropper.aspectRatio}
                                coords={props.cropper.coords}
                                dims={props.cropper.dims}
                                hidden={false}
                                name={props.cropper.name}
                                id={props.cropper.id}
                                overlay={props.cropper.selectedSize?.label === "1920x1080 (with overlay)"}
                            />
                        ) : (
                            <></>
                        )
                    }
                />
            </div>
            <div className="row centered buttons">
                {props.cancelButton && (
                    <p
                        className="button"
                        onClick={() => {
                            props.onClose();
                            setOpen(false);
                        }}>
                        Cancel
                    </p>
                )}
                <p className="button" onClick={handleSet}>
                    <RiImageAddFill />
                    Set
                </p>
                {props.showButton && (
                    <p className="button" onClick={handleShow}>
                        <RiImageAddFill />
                        Show
                    </p>
                )}
            </div>
        </div>
    );
};
